.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  margin: 1vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  margin-bottom: 3vmin;
}

.githubIcon {
  height: 4vmin;
  margin: 1vmin;
}

.App-footer {
  background-color: #282c34;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
